<template>
    <div>
         <div class="modal-content border-0 overflow-hidden">
                                    <div class="modal-header p-3">
                                        <h4 class="card-title mb-0">Transaction Pin</h4>
                                        <button type="button" id="btn" class="btn-close" data-bs-dismiss="modal"
                                            aria-label="Close"></button>
                                    </div>
                                    <div class="alert alert-warning  rounded-0 mb-0" v-if="authError == null">
                                        <p class="mb-0"> <span class="fw-semibold">You Cant perform Transactions without setting your transaction pin</span></p>
                                    </div>
                                     <div class="alert alert-danger  rounded-0 mb-0" v-else>
                                        <p class="mb-0"> <span class="fw-semibold">{{authError}}</span></p>
                                    </div>
                                    <div class="modal-body">
                                        <form   @submit.prevent="setpin">
                                            <div class="mb-3">
                                                <label for="transpin" class="form-label">Transaction Pin</label>
                                                <input type="password" class="form-control" id="transpin" v-model="pin" required
                                                    placeholder="Transaction Pin">
                                            </div>
                                            <div class="mb-3">
                                                <label for="ctranspin" class="form-label">Confirm Transaction Pin</label>
                                                <input type="password" class="form-control" id="ctranspin" v-model="pinc" required
                                                    placeholder="Confirm Transaction Pin">
                                            </div>
                                           
                                          
                                            <div class="text-end">
                                                <button type="submit" class="btn btn-primary" :disabled="tryingToLogIn"> <div class="spinner-border spinner-border-sm" role="status" v-if="tryingToLogIn"> 
  <span class="sr-only">Loading...</span>
</div>  Confirm Pin</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
    </div>
</template>


<script>
export default {
    data() {
        return {
      authError: null,
        pin: null,
        pinc: null,
        tryingToLogIn: false,
     
   
        events: ["click"], 
        }
    },
    methods: {
             async setpin() {



       this.tryingToLogIn = true;
try {
  
       
  var data = await this.$store.dispatch('setpin', {transpin: this.pin,transpin_confirmation: this.pinc});  

     this.tryingToLogIn = false;
        document.getElementById('btn').click()
   this.$router.push({name: 'dashboard'});
  if (data.status == false) {
    //  return this.authError == data.message;

  }            


       
} catch (error) {
   console.log(error.response);
    this.tryingToLogIn = false;
if (error.response != undefined) {
       
   return this.authError = error.response.data.data.message
} else {
      
   return this.authError = "Please make sure You pin matches"
}


 
}

},
 
    },
}
</script>